import axios from "axios";

const clientAxios = axios.create({
  baseURL: `${process.env.REACT_APP_REQUEST_BASE_URL}/api/external`,
  headers: {
    "X-Request-Source": "no_auth",
  },
});

export default clientAxios;
